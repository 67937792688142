import { useEffect, useState } from 'react';
import { fetchUsers } from 'services/adminService';
import Pagination from 'component/Pagination';
import { Timestamp } from 'firebase/firestore';
import SearchInput from 'component/SearchInput';
import { useNavigate } from 'react-router';
import { USDollar } from 'utils/utils';

const UserTable = () => {
    const [users, setUsers] = useState()

    const navigate = useNavigate()

    const [sortColumn, setSortColumn] = useState('created_at'); // Default column to sort
    const [sortOrder, setSortOrder] = useState('desc'); // Default: descending
    const [searchInput, setSearchInput] = useState('');

    const [lastVisibleStack, setLastVisibleStack] = useState([]); // Stack for navigating back
    const [usersPerPage, setUsersPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isNextPageAvailable, setIsNextPageAvailable] = useState(false);


    useEffect(() => {
        fetchUsers(currentPage, lastVisibleStack, usersPerPage, searchInput, sortColumn, sortOrder).then(res => {
            // Store the last document in the stack for future reference
            if (currentPage > lastVisibleStack.length) {
                setLastVisibleStack((prevStack) => [...prevStack, res.snapshot.docs[res.snapshot.docs.length - 1]]);
            }
            // Check if there is a next page
            setIsNextPageAvailable(res.snapshot.size === usersPerPage);
            setUsers(res.rows)
        })
    }, [currentPage, usersPerPage, sortColumn, sortOrder]);


    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleChangeLimit = (e) => {
        setCurrentPage(1)
        setUsersPerPage(Number(e.target.value))
    }

    const handleSortColumn = (column) => {
        if (sortColumn === column) {
            setSortOrder(prevSortOrder => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
        setCurrentPage(1);
    };

    const handleClearFilter = () => {
        // Reset all states
        setSearchInput('');
        setSortColumn('created_at');
        setSortOrder('desc');
        setCurrentPage(1);
        setLastVisibleStack([]);
    }

    const handleSearch = (searchTerm) => {
        setSearchInput(searchTerm);
        setCurrentPage(1); // Reset to the first page when searching
        // Fetch the users based on the new search input here
        fetchUsers(currentPage, lastVisibleStack, usersPerPage, searchTerm, sortColumn, sortOrder).then(res => {
            setUsers(res.rows);
        });
    };
    return (
        <div className="bg-white shadow rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
                <div className='flex-1'>
                    <SearchInput onSearch={handleSearch} />
                </div>
                <div>
                    {sortColumn !== 'created_at' && <button
                        onClick={handleClearFilter}
                        className="ml-4 text-red-500 p-2 rounded-md">
                        Clear filter
                    </button>}
                    <select
                        onChange={handleChangeLimit}
                        className="ml-4 p-2 border rounded-md">
                        <option value={10}>10 queries</option>
                        <option value={20}>20 queries</option>
                        <option value={50}>50 queries</option>
                    </select>
                </div>
            </div>
            <table className="w-full text-left">
                <thead>
                    <tr className="bg-gray-100 border-b">
                        <th className="p-3 font-medium text-gray-700 cursor-pointer">ID</th>
                        <th className="p-3 font-medium text-gray-700 cursor-pointer" onClick={() => handleSortColumn('name')}>
                            Name {sortColumn === 'name' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                        </th>
                        <th className="p-3 font-medium text-gray-700 cursor-pointer" onClick={() => handleSortColumn('email')}>
                            Email {sortColumn === 'email' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                        </th>
                        <th className="p-3 font-medium text-gray-700 cursor-pointer" onClick={() => handleSortColumn('created_at')}>
                            Register On {sortColumn === 'created_at' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                        </th>
                        <th className="p-3 font-medium text-gray-700 cursor-pointer" onClick={() => handleSortColumn('balance')}>
                            Balance {sortColumn === 'balance' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                        </th>
                        <th className="p-3 font-medium text-gray-700 cursor-pointer" onClick={() => handleSortColumn('bunnies')}>
                            Bunnies {sortColumn === 'bunnies' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map((user, index) => (
                        <tr key={index} className="border-b hover:bg-gray-50"
                            onClick={() => navigate(user.id)}
                        >
                            <td className="p-3">#{user.id.substring(0, 5)}</td>
                            <td className="p-3">{user.name}</td>
                            <td className="p-3">{user.email}</td>
                            <td className="p-3">{new Timestamp(user?.created_at?.seconds, user?.created_at?.nanoseconds).toDate().toLocaleString() ?? ''}</td>
                            <td className="p-3">{USDollar.format(user.balance / 100)}</td>
                            <td className="p-3">{user.bunnies ?? 0}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                currentPage={currentPage}
                onPageChange={handlePageChange}
                isNextPageAvailable={isNextPageAvailable}
            />
        </div>
    );
}
export default UserTable