import Header from "component/Header";
import Sidebar from "component/Sidebar";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchDishById } from "services/dishService";

import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { getImagePath } from "utils/utils";

const DishDetail = () => {
    const param = useParams();

    const [dish, setDish] = useState()
    const [isUpdateMode, setIsUpdateMode] = useState(false)

    useEffect(() => {
        console.log('id = ', param.id)

        fetchDishById(param.id).then(res => {
            setDish(res)
            console.log('dish = ', res)
        })

    }, [param.id])


    const handleChange = (e) => {
        const { name, value } = e.target;
        // Check if the name belongs to a nested property
        setDish({
            ...dish,
            [name]: value,
        });
    };
    return (<>
        <Header />
        <div className="bg-gray-100 flex">
            <Sidebar />
            <div className="flex-1 p-8 mb-10">

                {dish && <form>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <div className="flex justify-between items-center">
                                <h2 className="text-base/7 font-semibold text-gray-900">Dish Information of {dish.id}</h2>
                                {/* {!isUpdateMode &&
                                    <div>
                                        <button
                                            onClick={(e) => { e.preventDefault(); setIsUpdateMode(true) }}
                                            className="px-5 py-2.5 text-sm font-medium text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Edit</button>
                                    </div>} */}
                            </div>
                            <div>
                                <img src={getImagePath(`assets/temp/${dish.imageUrl}`)} alt={dish.name} className="w-40 h-40 rounded-md mt-4" />
                            </div>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8">
                                <div className="sm:col-span-3">
                                    <label htmlFor="dishName" className="block text-sm/6 font-medium text-gray-900">
                                        Dish name
                                    </label>
                                    <div className="mt-2">
                                        <input id="name" name="name"
                                            readOnly={!isUpdateMode}
                                            value={dish.name}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="variationName" className="block text-sm/6 font-medium text-gray-900">
                                        Dish type
                                    </label>
                                    <div className="mt-2">
                                        <input id="variationName" name="variationName"
                                            readOnly={!isUpdateMode}
                                            value={dish.variationName}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="variationName" className="block text-sm/6 font-medium text-gray-900">
                                        Dish sort order
                                    </label>
                                    <div className="mt-2">
                                        <input id="order" name="order"
                                            readOnly={!isUpdateMode}
                                            value={dish.order}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="description" className="block text-sm/6 font-medium text-gray-900">
                                        Description
                                    </label>
                                    <div className="mt-2">
                                        <input id="description" name="description"
                                            readOnly={!isUpdateMode}
                                            value={dish.description}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="price" className="block text-sm/6 font-medium text-gray-900">
                                        Price
                                    </label>
                                    <div className="mt-2">
                                        <input id="price" name="price"
                                            readOnly={!isUpdateMode}
                                            value={dish.price}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="category" className="block text-sm/6 font-medium text-gray-900">
                                        Category
                                    </label>
                                    <div className="mt-2 grid grid-cols-1">
                                        {
                                            isUpdateMode ?
                                                <>
                                                    <select
                                                        id="category"
                                                        name="category"
                                                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                                                    >
                                                        <option>Canada</option>
                                                        <option>Mexico</option>
                                                    </select>
                                                    <ChevronDownIcon
                                                        aria-hidden="true"
                                                        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                                                    />
                                                </>
                                                : <span className="bg-gray-300 col-start-1 row-start-1 w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">Canada</span>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    {isUpdateMode && <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button" className="text-sm/6 font-semibold text-gray-900"
                            onClick={(e) => { e.preventDefault(); setIsUpdateMode(false) }}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>}
                </form>}

            </div>
        </div>
    </>)
}
export default DishDetail
