import { useEffect, useState } from "react";
import { fetchModifierOfDish } from "services/dishService";

const ToppingPickModal = ({ isOpen, selectingOrderline, onSave, onClose }) => {

    const [requireOptions, setRequireOptions] = useState([]);
    const [customOptions, setCustomOptions] = useState([]);
    const [dish, setDish] = useState(null);
    const [selectedRequireOptions, setSelectedRequireOptions] = useState({});
    const [selectedCustomOptions, setSelectedCustomOptions] = useState({});


    useEffect(() => {
        if (isOpen) {
            console.log(selectingOrderline);

            setSelectedRequireOptions({});
            setSelectedCustomOptions({});
            fetchModifierOfDish(selectingOrderline.dishId).then(res => {
                console.log(res);
                setDish(res.dish);
                setRequireOptions(res.requireOptions);
                setCustomOptions(res.customOptions);
            })
        } else {
        }
    }, [isOpen, selectingOrderline]);

    if (!isOpen) return null;


    const handleOptionChange = (modGroup, mod) => {
        console.log('modGroup = ', modGroup);
        console.log('mod = ', mod);
        if (modGroup.isSingleChoose) {
            setSelectedRequireOptions((prev) => {
                const updatedOptions = { ...prev };
                updatedOptions[modGroup.groupName] = mod;
                return updatedOptions;
            })
            console.log('selectedRequireOptions = ', selectedRequireOptions);
        } else {
            setSelectedCustomOptions((prev) => {
                const updatedOptions = { ...prev };
                // Handle custom options (checkboxes)
                const selectedMods = updatedOptions[modGroup.groupName] || [];

                const isIncluded = selectedMods.findIndex((m) => m._id === mod._id) !== -1;
                if (isIncluded) {
                    updatedOptions[modGroup.groupName] = selectedMods.filter((m) => m._id !== mod._id);
                } else {
                    updatedOptions[modGroup.groupName] = [...selectedMods, mod];
                }
                return updatedOptions;
            });
            console.log('selectedCustomOptions = ', selectedCustomOptions);
        };
    };
    const handleSave = () => {
        // Implement save functionality here
        console.log("Save button clicked");
        if (requireOptions.length !== Object.values(selectedRequireOptions).length) {
            alert("Please select all required options");
            return;
        }
        const totalPrice = dish.price
            + Object.values(selectedRequireOptions).reduce((acc, option) => acc + option.price, 0)
            + Object.values(selectedCustomOptions).flat().reduce((acc, option) => acc + option.price, 0);

        const res = {
            dish: dish,
            orderLinePrice: totalPrice,
            requireOptions: Object.fromEntries(
                Object.entries(selectedRequireOptions).map(([key, value]) => [key, value.name])
            ),
            customOptions: Object.fromEntries(
                Object.entries(selectedCustomOptions).map(([group, options]) => [
                    group,
                    options.map((option) => `+ ${option.name}`).join("\n")
                ])
            ),
        }
        onSave(res);
    };

    return <>
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:min-h-[600px]">
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <h3 className="text-xl font-semibold text-gray-900" id="modal-title">Select Topping for: {dish && <span>{dish.name}</span>}</h3>
                                    {requireOptions.length > 0 && requireOptions?.map((modGroup, index) =>
                                        <div key={index} className="mt-10">
                                            <h4 key={index} className="text-lg font-semibold text-gray-900">{modGroup.groupName} <span className="text-red-400"> *required</span></h4>
                                            <div className="mt-3 space-y-2">
                                                {modGroup.options.map((mod) =>
                                                    <div key={mod.id} className="flex items-center mr-2">
                                                        <label className="flex items-start space-x-4 cursor-pointer">
                                                            <input type="radio"
                                                                id={mod.id} name={modGroup.groupName} value={mod.id}
                                                                onChange={() => handleOptionChange(modGroup, mod)}
                                                                className="mt-1 w-4 h-4 text-blue-600 focus:ring-blue-500" />
                                                            <div>
                                                                <p className="font-semibold text-gray-900">{mod.name}</p>
                                                                <p className="text-sm text-gray-500">${mod.price}</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {customOptions.length > 0 && customOptions?.map((modGroup, index) =>
                                        <div key={index} className="mt-3">
                                            <h4 key={index} className="text-lg font-semibold text-gray-900">{modGroup.groupName} <span className="text-gray-400"> *optional</span></h4>
                                            <div className="mt-3 space-y-2">
                                                {modGroup.options.map((mod) =>
                                                    <div key={mod.id} className="flex items-center mr-2">
                                                        <label class="flex items-start space-x-4 cursor-pointer">
                                                            <input type="checkBox" id={mod.id}
                                                                name={modGroup.groupName} value={mod.id}
                                                                onChange={() => handleOptionChange(modGroup, mod)}
                                                                className="mt-1 w-4 h-4 text-blue-600 focus:ring-blue-500" />
                                                            <div>
                                                                <p className="font-semibold text-gray-900">{mod.name}</p>
                                                                <p className="text-sm text-gray-500">${mod.price}</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => handleSave()}
                            >
                                Save
                            </button><button type="button"
                                onClick={onClose}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Close</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ToppingPickModal;