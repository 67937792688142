import { getFirestore, collection, getDocs, orderBy, limit, query, where, getDoc, doc, startAfter, startAt, onSnapshot, Timestamp, updateDoc, addDoc } from 'firebase/firestore';
import { app } from '../services/firebase';

const db = getFirestore(app);

export const subscribeOrderByStore = (selectedStoreAddress, onOrdersChange) => {
    const ordersCollection = collection(db, 'orders');
    const ordersQuery = query(
        ordersCollection,
        where('cart.shopAddress', '==', selectedStoreAddress),
        orderBy('createdAt', 'desc'),
        limit(10)
    );

    // Use onSnapshot to subscribe to real-time updates
    const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
        const ordersList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        // Pass the updated ordersList to the callback
        onOrdersChange(ordersList);
    });

    // Return the unsubscribe function to allow for cleanup
    return unsubscribe;
};

export const subscribeOrderAllStore = (onOrdersChange) => {
    const ordersCollection = collection(db, 'orders');
    const ordersQuery = query(
        ordersCollection,
        orderBy('createdAt', 'desc'),
        limit(10)
    );

    // Use onSnapshot to subscribe to real-time updates
    const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
        const ordersList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        // Pass the updated ordersList to the callback
        onOrdersChange(ordersList);
    });

    // Return the unsubscribe function to allow for cleanup
    return unsubscribe;
};

export const updateOrderStatus = async (order, newStatus) => {
    try {
        const orderId = order.id
        const orderRef = doc(db, 'orders', orderId);
        await updateDoc(orderRef, {
            orderStatus: newStatus,
            updatedAt: Timestamp.now()
        });


        if (newStatus === 'COMPLETED' && orderId != null && await checkBunnyTransactionOrder(orderId)) {
            //create bunnies transaction
            if (order.cart.totalAmount >= 5) {
                const transactionBunnies = {
                    amount: Math.floor(order.cart.totalAmount * 10),
                    uid: order.customerModel.uid,
                    type: 'ORDER',
                    status: 'RETURNED',
                    created_at: Timestamp.now(),
                    sourceDishId: null,
                    sourceOrderId: orderId,
                    sourceRatingId: null,
                    sourceReferralId: null,
                    updated_at: Timestamp.now()
                }
                //approved
                console.log('approved: ', transactionBunnies)
                const newTransactionId =
                    await saveBunnyTransaction(transactionBunnies);
                await saveTransactionBunniesReference(transactionBunnies.amount,
                    newTransactionId, order.customerModel.uid);
            }
        }
        console.log(`Order ${orderId} status updated to ${newStatus}`);
    } catch (error) {
        console.error("Error updating order status:", error);
    }
}

const saveTransactionBunniesReference = async (bunnyTransactionAmount, newTransactionId, uid) => {
    try {
        // Get the user document reference
        const userRef = doc(db, 'users', uid);
        const userSnapshot = await getDoc(userRef);

        if (!userSnapshot.exists()) {
            throw new Error(`User with uid ${uid} does not exist`);
        }

        // Retrieve user data and update fields
        const userModel = userSnapshot.data();

        // Extract current bunnies balance and bunny transactions
        const currentBunniesBalance = userModel.bunnies || 0;
        const bunnyTransactions = userModel.bunniesTransactions || [];

        // Update the user document
        await updateDoc(userRef, {
            bunnies: currentBunniesBalance + bunnyTransactionAmount,
            bunniesTransactions: [...bunnyTransactions, newTransactionId], // Add new transaction ID to the list
            updated_at: Timestamp.now() // Use the current date and time
        });

        console.log('Transaction reference saved successfully.');
    } catch (error) {
        console.error("Error saving transaction reference:", error);
    }
};

const saveBunnyTransaction = async (request) => {
    try {
        // Add the request data to the "bunnies" collection
        const bunniesRef = collection(db, 'bunnies');
        const ref = await addDoc(bunniesRef, request);

        // Update the document with its generated ID
        await updateDoc(ref, { id: ref.id });

        // Return the document ID
        return ref.id;
    } catch (error) {
        console.error("Error saving bunny transaction:", error);
        throw error;
    }
};

const checkBunnyTransactionOrder = async (orderId) => {
    const cl = collection(db, 'bunnies')
    const q = query(cl, where('sourceOrderId', '==', orderId))
    const bunnyTransaction = await getDocs(q)
    return bunnyTransaction.empty;
}

export const fetchOrderByStore = async (selectedStoreAddress, currentPage, lastVisibleStack, rowsPerPage) => {
    const ordersCollection = collection(db, 'orders');
    let orderQuery;

    if (currentPage === 1) {
        // Fetch the first page
        if (selectedStoreAddress === 'all') {
            orderQuery = query(ordersCollection,
                orderBy('createdAt', 'desc'),
                limit(rowsPerPage));
        } else {
            orderQuery = query(ordersCollection,
                where("cart.shopAddress", "==", selectedStoreAddress),
                orderBy('createdAt', 'desc'),
                limit(rowsPerPage));
        }
    } else if (currentPage > lastVisibleStack?.length) {
        // Fetch the next page after the last visible document of the previous page
        const lastVisible = lastVisibleStack[lastVisibleStack?.length - 1];
        if (selectedStoreAddress === 'all') {
            orderQuery = query(ordersCollection,
                orderBy('createdAt', 'desc'),
                startAfter(lastVisible),
                limit(rowsPerPage));
        } else {
            orderQuery = query(ordersCollection,
                where("cart.shopAddress", "==", selectedStoreAddress),
                orderBy('createdAt', 'desc'),
                startAfter(lastVisible),
                limit(rowsPerPage));
        }
    } else {
        // Fetch the previous page using the document at the start of the previous page
        const prevLastVisible = lastVisibleStack[currentPage - 2];
        if (selectedStoreAddress === 'all') {
            orderQuery = query(ordersCollection,
                orderBy('createdAt', 'desc'),
                startAt(prevLastVisible),
                limit(rowsPerPage));
        } else {
            orderQuery = query(ordersCollection,
                where("cart.shopAddress", "==", selectedStoreAddress),
                orderBy('createdAt', 'desc'),
                startAt(prevLastVisible),
                limit(rowsPerPage));
        }
    }

    const snapshot = await getDocs(orderQuery);
    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });
    console.log('orderList = ', rows)
    return { snapshot, rows };
}

export const createOrderFromAdmin = async (order) => {
    try {
        const cl = collection(db, 'orders');
        const ref = await addDoc(cl, order);

        // Update the document with its generated ID
        await updateDoc(ref, { id: ref.id });

        // Return the document ID
        return ref.id;
    } catch (error) {
        console.error("Error create order:", error);
        throw error;
    }
};
