import { useContext, useEffect, useState } from 'react';
import AuthContext from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Pagination from 'component/Pagination';
import { fetchOrderByStore, updateOrderStatus } from 'services/adminService';
import { USDollar } from 'utils/utils';

const OrderHistoryTable = () => {

    const { selectedStore, findStoreTitleByAddress } = useContext(AuthContext)
    const [orders, setOrders] = useState([]);

    const [sortColumn, setSortColumn] = useState('createdAt'); // Default column to sort
    const [sortOrder, setSortOrder] = useState('desc'); // Default: descending

    const navigate = useNavigate()

    const [lastVisibleStack, setLastVisibleStack] = useState([]); // Stack for navigating back
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isNextPageAvailable, setIsNextPageAvailable] = useState(false);


    useEffect(() => {
        fetchOrderByStore(selectedStore === 'all' ? 'all' : selectedStore?.address, currentPage, lastVisibleStack, rowsPerPage, sortColumn, sortOrder)
            .then(res => {
                // Store the last document in the stack for future reference
                if (currentPage > lastVisibleStack.length) {
                    setLastVisibleStack((prevStack) => [...prevStack, res.snapshot.docs[res.snapshot.docs.length - 1]]);
                }
                // Check if there is a next page
                setIsNextPageAvailable(res.snapshot.size === rowsPerPage);
                setOrders(res.rows)
            })
    }, [selectedStore, currentPage, rowsPerPage, lastVisibleStack, sortColumn, sortOrder,]);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleChangeLimit = (e) => {
        setLastVisibleStack(prevStack => []);
        setCurrentPage(1)
        setRowsPerPage(Number(e.target.value))
    }

    const handleStatusChange = async (e, order) => {
        const newStatus = e.target.value;
        console.log(`Order ID: ${order.id}, New Status: ${newStatus}`);

        try {
            // Call service to update order status (this function should be defined in your service file)
            await updateOrderStatus(order, newStatus);
            // No need to manually setOrders here since onSnapshot will update automatically

        } catch (error) {
            console.error("Error updating order status:", error);
        }
    };

    const handleSortColumn = (column) => {
        if (sortColumn === column) {
            setSortOrder(prevSortOrder => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
        setCurrentPage(1);
    };

    const handleClearFilter = () => {
        // Reset all states
        setSortColumn('createdAt');
        setSortOrder('desc');
        setCurrentPage(1);
        setLastVisibleStack([]);
    }

    return (
        <>
            <div className="bg-white shadow rounded-lg p-4 overflow-x-auto">
                <div className="flex justify-between items-center mb-4">
                    {/* <input type="text" placeholder="Search order" className="w-full max-w-xs pl-4 pr-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300" /> */}
                    <h3 className='start text-orange-600'>
                        *Notice: This page is only used for viewing order history, not for tracking new orders.                    </h3>
                    <div>

                        {sortColumn !== 'createdAt' && <button
                            onClick={handleClearFilter}
                            className="ml-4 justify-content-end text-red-500 p-2 rounded-md">
                            Clear filter
                        </button>}
                        <select className="ml-4 p-2 border rounded-md"
                            onChange={handleChangeLimit}>
                            <option value={10}>10 queries</option>
                            <option value={20}>20 queries</option>
                            <option value={50}>50 queries</option>
                        </select>
                    </div>
                </div>
                <table className="w-full text-left">
                    <thead>
                        <tr className="bg-gray-100 border-b">
                            {/* <th className="p-3 font-medium text-gray-700">ID</th> */}
                            <th className="p-3 font-medium text-gray-700 cursor-pointer" onClick={() => handleSortColumn('createdAt')}>
                                ORDER AT {sortColumn === 'createdAt' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                            </th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">STORE ORDERED</th>
                            {/* <th className="p-3 font-medium text-gray-700">TYPE</th> */}
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">CUSTOMER NAME</th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">ITEM COUNT</th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer" onClick={() => handleSortColumn('cart.totalAmount')}>
                                TOTAL AMOUNT {sortColumn === 'cart.totalAmount' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                            </th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer" onClick={() => handleSortColumn('orderStatus')}>
                                ORDER STATUS {sortColumn === 'orderStatus' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                            </th>
                            <th className="p-3 font-medium text-gray-700">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders && orders?.map((order, index) => (
                            <tr key={index} className="border-b hover:bg-gray-50" onClick={() => navigate(order.id)}>
                                {/* <td className="p-3">#{order?.id?.substring(0, 5)}</td> */}
                                <td className="p-3">{order.createdAt ? new Date(order.createdAt).toLocaleString() : ''}</td>
                                <td className="p-3">{findStoreTitleByAddress(order.cart.shopAddress)}</td>
                                {/* <td className="p-3">{order.cart.deliveryType}</td> */}
                                <td className="p-3">{order.customerModel?.name}</td>
                                <td className="p-3">{order.cart.orderLines?.length} items</td>
                                <td className="p-3">{USDollar.format(order.cart.totalAmount)}</td>
                                <td className="p-3 text-green-600 font-semibold">{order.orderStatus}</td>
                                <td className="p-3 text-gray-500">
                                    <select className="ml-4 p-2 border rounded-md"
                                        onChange={(e) => handleStatusChange(e, order)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from propagating to the row
                                    >
                                        <option value='NEW'>Set as NEW</option>
                                        <option value='PROCESS'>Set as PROCESS</option>
                                        <option value='DELIVERY'>Set as DELIVERY</option>
                                        <option value='COMPLETED'>Set as COMPLETED</option>
                                        <option value='CANCELLED'>Set as CANCELLED</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                currentPage={currentPage}
                onPageChange={handlePageChange}
                isNextPageAvailable={isNextPageAvailable}
            />
        </>
    );
}
export default OrderHistoryTable