import { useContext, useEffect, useState } from 'react';
import AuthContext from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Pagination from 'component/Pagination';
import { fetchCategories, fetchDishes } from 'services/dishService';
import { getImagePath } from 'utils/utils';
import { Timestamp } from 'firebase/firestore';
import SearchInput from 'component/SearchInput';

const DishTable = () => {

    const { selectedStore } = useContext(AuthContext)
    const [dishes, setDishes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectingCategory, setSelectingCategory] = useState('');

    // const [sortColumn, setSortColumn] = useState('categoryId'); // Default column to sort
    // const [sortOrder, setSortOrder] = useState('asc'); // Default: descending
    const [searchInput, setSearchInput] = useState('');

    const navigate = useNavigate()

    const [lastVisibleStack, setLastVisibleStack] = useState([]); // Stack for navigating back
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isNextPageAvailable, setIsNextPageAvailable] = useState(false);


    useEffect(() => {
        fetchDishes(currentPage, lastVisibleStack, rowsPerPage, searchInput.toUpperCase(), selectingCategory)
            .then(res => {
                // Store the last document in the stack for future reference
                if (currentPage > lastVisibleStack.length) {
                    setLastVisibleStack((prevStack) => [...prevStack, res.snapshot.docs[res.snapshot.docs.length - 1]]);
                }
                // Check if there is a next page
                setIsNextPageAvailable(res.snapshot.size === rowsPerPage);
                setDishes(res.rows)
            });
    }, [selectedStore, currentPage, rowsPerPage, searchInput, selectingCategory]);

    useEffect(() => {
        fetchCategories().then(res => {
            setCategories(res.rows)
        })

    }, []);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleChangeLimit = (e) => {
        setLastVisibleStack(prevStack => []);
        setCurrentPage(1)
        setRowsPerPage(Number(e.target.value))
    }

    const handleChangeCategory = (e) => {
        setLastVisibleStack(prevStack => []);
        setSearchInput('')
        setCurrentPage(1);
        setSelectingCategory(e.target.value)
    }

    // const handleSortColumn = (column) => {
    //     if (sortColumn === column) {
    //         setSortOrder(prevSortOrder => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    //     } else {
    //         setSortColumn(column);
    //         setSortOrder('asc');
    //     }
    //     setCurrentPage(1);
    // };

    const handleClearFilter = () => {
        // Reset all states
        // setSortColumn('createdAt');
        // setSortOrder('desc');
        setSearchInput('')
        setCurrentPage(1);
        setLastVisibleStack([]);
    }

    const handleSearch = (searchTerm) => {
        setSearchInput(searchTerm.toUpperCase());
        setCurrentPage(1); // Reset to the first page when searching
        // Fetch the dishes based on the new search input here
        fetchDishes(currentPage, lastVisibleStack, rowsPerPage, searchTerm.toUpperCase()).then(res => {
            setDishes(res.rows);
        });
    };

    return (
        <>
            <div className="bg-white shadow rounded-lg p-4 overflow-x-auto">
                <div className="flex justify-between items-center mb-4">
                    <div className='flex-1'>
                        <SearchInput placeholder={'Search Dish'} onSearch={handleSearch} />
                    </div>
                    <div className='flex-1'>
                        <select className="ml-4 p-2 border rounded-md"
                            onChange={handleChangeCategory}>
                            <option value=''>SELECT CATEGORY</option>
                            {categories && categories?.map(category =>
                                <option key={category.id} value={category.id}>{category.name}</option>
                            )}
                        </select>
                    </div>
                    {/* <input type="text" placeholder="Search order" className="w-full max-w-xs pl-4 pr-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300" /> */}
                    <div>

                        {searchInput !== '' && <button
                            onClick={handleClearFilter}
                            className="ml-4 justify-content-end text-red-500 p-2 rounded-md">
                            Clear filter
                        </button>}
                        <select className="ml-4 p-2 border rounded-md"
                            onChange={handleChangeLimit}>
                            <option value={10}>10 queries</option>
                            <option value={20}>20 queries</option>
                            <option value={50}>50 queries</option>
                        </select>
                    </div>
                </div>
                <table className="w-full text-left">
                    <thead>
                        <tr className="bg-gray-100 border-b">
                            {/* <th className="p-3 font-medium text-gray-700">TYPE</th> */}
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">Image</th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">ID</th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">Name</th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">Order(sort)</th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">Price</th>
                            <th className="p-3 font-medium text-gray-700">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dishes && dishes?.map((dish, index) => (
                            <tr key={index} className="border-b hover:bg-gray-50"
                            onClick={() => navigate(dish.id)}
                            >
                                {/* <td className="p-3">#{dish?.id?.substring(0, 5)}</td> */}
                                <td className="p-3">
                                    <img src={getImagePath(`assets/temp/${dish.imageUrl}`)} alt={dish.dishName} className="w-16 h-16 rounded-md mr-4" />
                                </td>
                                <td className="p-3">{dish?.id}</td>
                                <td className="p-3">{dish?.name}</td>
                                <td className="p-3">{dish?.order}</td>
                                <td className="p-3">${dish?.price}</td>
                                <td className="p-3">{dish?.created_at ? new Timestamp(dish?.created_at?.seconds, dish?.created_at?.nanoseconds).toDate().toLocaleString() : ''}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Pagination
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    isNextPageAvailable={isNextPageAvailable}
                />
            </div>
        </>
    );
}
export default DishTable