import { Timestamp } from 'firebase/firestore';
import React from 'react';
import { USDollar } from 'utils/utils';

const UserProfileCard = ({ user }) => {

    return (
        <div className="mx-auto p-6 bg-white shadow rounded-lg">
            <div className="flex flex-col lg:flex-row gap-6">
                {/* Left Section - Account Data */}
                {user && <div className="flex-1 p-6 rounded-lg shadow-sm">
                    {/* <h2 className="text-2xl font-semibold mb-4">User data</h2> */}
                    <div className="flex items-start gap-4">
                        {/* User Avatar */}
                        {/* <img
                            src="https://randomuser.me/api/portraits/men/32.jpg"
                            alt="User Avatar"
                            className="w-24 h-24 rounded-full object-cover"
                        /> */}
                        <div>
                            {/* User Info */}
                            {/* <span className="bg-blue-200 text-blue-800 text-xs font-semibold px-2 py-1 rounded">Essentials</span> */}
                            <h3 className="text-xl mt-2"><strong>Name: </strong> {user.name}</h3>
                            <div className="mt-4">
                                <div className="text-sm mb-2">
                                    <strong>Email Address:</strong> {user.email}</div>
                                <div className="text-sm mb-2">
                                    <strong>Address:</strong> {user.address}
                                </div>
                                <div className="text-sm mb-2">
                                    <strong>Registered:</strong> {new Timestamp(user?.created_at?.seconds, user?.created_at?.nanoseconds).toDate().toLocaleString() ?? ''}
                                </div>
                                <div className="text-sm mb-2">
                                    <strong>Balance:</strong> ${USDollar.format(user.balance / 100)}
                                </div>
                                <div className="text-sm mb-2">
                                    <strong>Bunnies:</strong> {user.bunnies ?? 0}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Edit Button */}
                    <div className="mt-6">
                        {/* <button className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md">
                            <svg
                                className="w-4 h-4 mr-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M11 17l-5 5m0 0l-5-5m5 5V12a9 9 0 0118 0v3"
                                />
                            </svg>
                            Edit profile data
                        </button> */}
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default UserProfileCard;
