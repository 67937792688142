import Sidebar from "component/Sidebar"
import OrderCreateForm from "./OrderCreateForm"
import Header from "component/Header"


const OrderCreate = () => (
    <>
        <Header root={true} />
        <div className="min-h-screen bg-gray-100 flex flex-col lg:flex-row">
            <Sidebar />
            <div className="flex-1 p-4 md:p-8">
                {/* <TopBar /> */}
                <div className="w-full h-full bg-gray-200 border-dashed border-4 border-gray-300">
                    <OrderCreateForm />
                </div>
            </div>
        </div>
    </>
)

export default OrderCreate