import ImageGalleryDialog from "component/ImageGalleryDialog";
import Pagination from "component/Pagination";
import { Timestamp } from "firebase/firestore";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { approveRating, fetchRating } from "services/ratingService";

const RatingTable = () => {

    const { user } = useAuth()
    const [ratings, setRatings] = useState([])

    const navigate = useNavigate()

    const [lastVisibleStack, setLastVisibleStack] = useState([]); // Stack for navigating back
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isNextPageAvailable, setIsNextPageAvailable] = useState(false);

    const fetchData = async () => {
        await fetchRating(currentPage, lastVisibleStack, rowsPerPage).then(res => {
            // Store the last document in the stack for future reference
            if (currentPage > lastVisibleStack.length) {
                setLastVisibleStack((prevStack) => [...prevStack, res.snapshot.docs[res.snapshot.docs.length - 1]]);
            }

            // Check if there is a next page
            setIsNextPageAvailable(res.snapshot.size === rowsPerPage);
            setRatings(res.rows)
        })
    }

    useEffect(() => {
        fetchData();
    }, [currentPage, rowsPerPage]);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleChangeLimit = (e) => {
        setCurrentPage(1)
        setRowsPerPage(Number(e.target.value))
    }

    const handleApprove = async (rating) => {
        await approveRating(rating, user.uid)
        fetchData()
    }

    return (<>
        <div className="bg-white shadow rounded-lg p-4 overflow-x-auto">
            <div className="flex justify-end items-center mb-4">
                <select className="ml-4 p-2 border rounded-md"
                    onChange={handleChangeLimit}>
                    <option value={10}>10 queries</option>
                    <option value={20}>20 queries</option>
                    <option value={50}>50 queries</option>
                </select>
            </div>
            <table className="w-full text-left">
                <thead>
                    <tr className="bg-gray-100 border-b">
                        {/* <th className="p-3 font-medium text-gray-700">ID</th> */}
                        <th className="p-3 font-medium text-gray-700">CUSTOMER</th>
                        <th className="p-3 font-medium text-gray-700">DISH</th>
                        {/* <th className="p-3 font-medium text-gray-700">TYPE</th> */}
                        <th className="p-3 font-medium text-gray-700">RATED</th>
                        <th className="p-3 font-medium text-gray-700">COMMENT</th>
                        <th className="p-3 font-medium text-gray-700">IMAGES</th>
                        <th className="p-3 font-medium text-gray-700">RATED AT</th>
                        <th className="p-3 font-medium text-gray-700">ACTION</th>
                    </tr>
                </thead>
                <tbody>
                    {ratings && ratings.map((rating, index) => (
                        <tr key={index} className="border-b hover:bg-gray-50"
                        // onClick={() => navigate(order.id)}
                        >
                            {/* <td className="p-3">#{order?.id?.substring(0, 5)}</td> */}
                            <td className="p-3 text-blue-700 hover:text-blue-400"
                                onClick={() => { navigate(`/admin/user/${rating.customerId}`) }}
                            >{rating.customerData?.name}</td>
                            {/* <td className="p-3">{rating.customerData?.email}</td> */}
                            <td className="p-3">{rating.dishId}</td>
                            <td className="p-3">{rating.ratingCount}</td>
                            <td className="p-3">{rating.ratingComment.length !== 0 ? rating.ratingComment : (<h4 className="italic text-gray-400 text-s">No comment</h4>)}</td>
                            <td className="p-3">
                                <ImageGalleryDialog images={rating.ratingImages} />
                            </td>
                            <td className="p-3">{new Timestamp(rating?.createdAt?.seconds, rating?.createdAt?.nanoseconds).toDate().toLocaleString() ?? ''}</td>
                            <td className="p-3">
                                {rating.accepted && rating.acceptedAdminId.length !== 0 ?
                                    <h4 className="text-green-500">
                                        Accepted
                                    </h4> :
                                    <button
                                        className="bg-orange-400 text-white rounded-md px-3 py-2 font-sm"
                                        onClick={() => handleApprove(rating)}
                                    >
                                        Approve
                                    </button>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                currentPage={currentPage}
                onPageChange={handlePageChange}
                isNextPageAvailable={isNextPageAvailable}
            />
        </div>
    </>)
}
export default RatingTable