import DishPickTable from "page/dish/DishPickTable";

const PickDishModal = ({ isOpen, onSelectDish, onClose }) => {

    if (!isOpen) return null;

    return <>
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:min-h-[600px]">
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div>
                                {/* <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                                    <img className="size-10 text-red-600" src={bell} alt="loading..." />
                                </div> */}
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <h3 className="text-xl font-semibold text-gray-900" id="modal-title">Select Dish</h3>
                                    {/* {newOrder &&
                                        <div className="mt-3">
                                            <p><strong>Order ID:</strong> {newOrder.id}</p>
                                            <p><strong>Status:</strong> {newOrder.orderStatus}</p>
                                            <p><strong>Customer:</strong> {newOrder.customerModel?.name}</p>
                                            <p><strong>Total Amount:</strong> ${newOrder.cart.totalAmount?.toFixed(2)}</p>
                                        </div>} */}
                                        <DishPickTable onSelectDish={onSelectDish}/>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button type="button"
                                onClick={onClose}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default PickDishModal;